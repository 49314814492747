@import '../../assets/styles/vars.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 133px;
  @include tablet {
    margin-top: 71px;
  }
  @include tablet-portrait {
    margin-top: 80px;
  }
  @include mobile {
    margin-top: 40px;
  }
}

.title {
  font-family: $bonanova;
  font-size: 96px;
  font-weight: 400;
  line-height: 86.4px;
  text-align: center;
  text-transform: uppercase;

  @include tablet {
    font-size: 61px;
    line-height: 55px;
  }
  @media (max-width: 1535px) {
    font-size: 59px;
    line-height: 53px;
  }
  @include tablet-portrait {
    font-size: 62px;
    line-height: 55.8px;
  }
  @include mobile {
    font-size: 36px;
    line-height: 32.4px;
  }

  span {
    font-size: 148px;
    line-height: 133.2px;

    @include tablet {
      font-size: 99px;
      line-height: 89.1px;
    }
    @include tablet-portrait {
      font-size: 107px;
      line-height: 96.3px;
    }
    @include mobile {
      font-size: 61px;
      line-height: 54.9px;
    }
  }
}

.description {
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  width: 777px;
  max-width: 100%;
  margin: 20px auto 0 auto;
  @include tablet {
    font-size: 20px;
    line-height: 28px;
    margin: 16px auto 0 auto;
    width: 524px;
  }
  @include tablet-portrait {
    br {
      display: none;
    }
  }
  @include mobile {
    margin: 8px auto 0 auto;
    font-size: 16px;
    line-height: 20.8px;
    width: 100%;
  }

  .mb {
    display: none;
    @include mobile {
      display: block;
    }
  }
}

.read {
  margin-top: 120px;
  position: relative;
  width: 364px;
  height: 79px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark;
  z-index: 1;
  @include hover {
    cursor: pointer;
    color: $white;
    svg {
      path {
        fill: $orange;
      }
    }
  }
  @include tablet {
    margin-top: 90px;
    width: 262px;
    height: 57px;
    font-size: 18px;
    line-height: 23.4px;
  }
  @include tablet-portrait {
    width: 214px;
    height: 47px;
    font-size: 14px;
    line-height: 18.2px;
    margin-top: 60px;
  }
  @include mobile {
    margin-top: 28px;
  }
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s all;

    path {
      transition: 0.3s all;
    }
  }
}

.video {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  @include tablet-portrait {
    position: fixed;
    top: 435px;
    bottom: unset;
    align-items: flex-start;

    @media (min-height: 1260px) {
      top: unset;
      bottom: 0;
      align-items: flex-end;
    }
  }
  @include mobile {
    top: 312px;

    @media (min-height: 870px) {
      top: unset;
      bottom: 0;
      align-items: flex-end;
    }
  }
  video {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: right;
  }
}

.videoRight {
  width: 670px;
  right: 0;
  @include tablet {
    width: 534px;
  }
  @media (max-width: 1536px) {
    width: 445px;
  }
  @media (max-width: 1440px) {
    width: 572px;
  }
  @media (max-width: 1360px) {
    width: 475px;
  }
  @include tablet-portrait {
    width: 516px;
    right: -107px;
  }
  @include mobile {
    width: 341px;
    right: -73px;
  }
}

.imgLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  @include tablet {
  }
  @include tablet-portrait {
    position: fixed;
    top: 425px;
    bottom: unset;
    align-items: flex-start;
    @media (min-height: 1260px) {
      top: unset;
      bottom: 0;
      align-items: flex-end;
    }
  }
  @include mobile {
    top: 296px;
    left: -89px;
    @media (min-height: 870px) {
      top: unset;
      bottom: 0;
      align-items: flex-end;
    }
  }

  img {
    width: 796px;
    max-height: 100%;
    object-fit: contain;
    object-position: left;
    @include tablet {
      width: 637px;
    }
    @media (max-width: 1536px) {
      width: 567px;
    }
    @media (max-width: 1440px) {
      width: 682px;
    }
    @media (max-width: 1360px) {
      width: 531px;
    }
    @include tablet-portrait {
      width: 616px;
    }
    @include mobile {
      width: 423px;
      max-width: none;
    }
  }
}
