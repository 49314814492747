@import '../../assets/styles/vars.scss';

.team {
  color: $dark;
  margin-top: 80px;
  position: relative;
  @include tablet {
    margin-top: 60px;
  }
  @include tablet-portrait {
    margin-top: 30px;
  }
  @include mobile {
    margin-top: 40px;
  }
  &__text-block {
    display: flex;
    align-items: flex-start;
    @include tablet-portrait {
      display: flex;
      flex-direction: column;
    }
    @include mobile {
      position: relative;
    }
  }
  &__text {
    width: 891px;
    max-width: 100%;
    margin-right: 47px;
    @include tablet {
      width: 633px;
      margin-right: 34px;
    }
    @include tablet-portrait {
      width: 100%;
    }
    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 30px;
    margin-bottom: 46px;
    @include tablet {
      grid-template-columns: 288px 317px;
      gap: 20px;
      margin-bottom: 36px;
    }
    @include tablet-portrait {
      grid-template-columns: 340px 293px;
      gap: 16px;
      margin-bottom: 30px;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      gap: 0;
    }
  }

  &__column {
    font-size: 24px;
    line-height: 34.8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    @include tablet {
      font-size: 18px;
      line-height: 25.2px;
    }
    @include tablet-portrait {
      font-size: 16px;
      line-height: 23.2px;
      letter-spacing: 0.005em;
    }
    & > div {
      margin-bottom: 24px;
      @include tablet-portrait {
        margin-bottom: 20px;
      }
      @include mobile {
        margin-bottom: 16px;
      }

      div:not(:last-child) {
        margin-bottom: 16px;
        @include tablet-portrait {
          margin-bottom: 12px;
        }
      }
    }
  }
}
.team-img {
  width: 936px;
  margin-top: -53px;
  animation: moveAround 3s infinite alternate ease-in-out;

  @include tablet {
    width: 602px;
    margin-top: -38px;
  }
  @include tablet-portrait {
    width: 540px;
    margin-top: 0;
    position: relative;
  }
  @include mobile {
    width: 100%;
  }
  img {
    width: 100%;
    max-width: 100%;
    @include tablet-portrait {
      position: relative;
      top: 121px;
      left: 0;
      width: 100%;
    }
    @include mobile {
      top: 0;
    }
  }
}

@keyframes moveAround {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}
