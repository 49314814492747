@import '../../assets/styles/vars.scss';

.text {
  width: 931px;
  @include tablet {
    width: 595px;
  }
  @include tablet-portrait {
    width: 512px;
  }
  @include mobile {
    width: calc(100% + 4px);
  }
  @include mobile {
    top: 120px;
  }
  br {
    display: none;
    @include mobile {
      display: block;
    }
  }

  p {
    z-index: 1;
    position: relative;
  }
}
.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  @include tablet-portrait {
    flex-direction: column;
  }
}

.first {
  font-family: $bonanova;
  font-size: 68.57px;
  line-height: 72px;
  color: $orange;
  position: relative;
  top: 2px;
  @include tablet {
    font-size: 40px;
    line-height: 42px;
    top: 1px;
  }
  @include mobile {
    font-size: 36px;
    line-height: 37.8px;
  }
}

.videoBlock {
  position: relative;
  width: 100%;
  @include tablet-portrait {
    display: flex;
    justify-content: center;
  }
  video {
    width: 980px;
    position: absolute;
    right: 0;
    top: 11px;
    @include tablet {
      width: 632px;
    }
    @include tablet-portrait {
      position: relative;
      width: 425px;
    }
    @include mobile {
      width: 100%;
    }
  }
}

.img {
  position: absolute;
  video {
    width: 100%;
  }

  &__left_1 {
    top: 79px;
    right: calc(100% + 78px);
    width: 399px;
    @include tablet {
      width: 288px;
      top: 35px;
      right: calc(100% + 17px);
    }
    @include tablet-portrait {
      width: 178px;
      left: -160px;
      top: -92px;
    }
    @include mobile {
      width: 120px;
      left: 40px;
      top: -113px;
      right: unset;
    }
  }
  &__right_1 {
    top: 277px;
    right: -413px;
    width: 520px;
    @include tablet {
      width: 384px;
      top: 170px;
      right: -283px;
    }
    @include tablet-portrait {
      width: 378px;
      top: calc(100% + 9px);
      bottom: unset;
      right: -71px;
      left: unset;
    }
    @include mobile {
      width: 195px;
      top: calc(100% + 19px);
      right: 15px;
    }
    @media (max-width: 374px) {
      top: calc(100% - 19px);
    }
  }
}

.orangeBorder {
  margin-left: 10px;
  @include mobile {
    margin-left: 0;
  }
  svg {
    left: -9px;
  }
}
