@import '../../assets/styles/vars.scss';

.header {
  z-index: 2;
  &__wrap {
    height: 79px;
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tablet {
      height: 52px;
      margin-top: 25px;
    }
    @include tablet-portrait {
      height: 40px;
      margin-top: 20px;
    }
    @include mobile {
      margin-top: 16px;
    }
  }

  &__link {
    &_about {
      position: relative;
      font-family: $manrope;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
      color: $white;
      @include hover {
        &::after {
          display: none;
        }
      }
      @include tablet {
        font-size: 18px;
        line-height: 23.4px;
      }
      &::after {
        content: '';
        display: block;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA5IiBoZWlnaHQ9IjMiIHZpZXdCb3g9IjAgMCAxMDkgMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMS4xNDEyNkMxMy4wMDczIDEuMTQxMjYgMjUuMDE0NyAxLjE0MTI2IDM3LjAyMiAxLjE0MTI2QzQzLjEyNTQgMS4xNDEyNiA0OS4yMjg3IDEuMTQxMjYgNTUuMzMyIDEuMTQxMjZDNjQuODYyIDEuMTQxMjYgNzQuMzUyMyAxLjkzMTcgODMuODc5NSAxLjk5ODczQzkxLjE0OTQgMi4wNDk4NyAxMDAuNzU2IDAuNTQxMzg2IDEwOCAxLjE0MTM2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K);
        height: 3px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        width: 100%;
        pointer-events: none;
      }
    }
    &_read {
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $inter;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $white;
      width: 232px;
      height: 72px;
      @include hover {
        path {
          fill: $dark;
        }
      }
      @include tablet {
        width: 184px;
        height: 57px;
        font-size: 18px;
        line-height: 23.4px;
      }
      @include tablet-portrait {
        // display: none;
        width: 165px;
        height: 40px;
        font-size: 14px;
        line-height: 18.2px;
      }
      @include mobile {
        width: 125px;
      }
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
  }
}

.logo {
  width: 253px;
  display: none;
  @include tablet {
    width: 187px;
  }
  @include tablet-portrait {
    width: 140px;
  }
}
