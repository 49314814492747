@font-face {
  font-family: Manrope;
  src: url('../fonts/Manrope-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url('../fonts/Manrope-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Manrope;
  src: url('../fonts/Manrope-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('../fonts/Inter_18pt-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: BonaNova;
  src: url('../fonts/BonaNova-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
