@import '../../assets/styles/vars.scss';

.footer {
  padding-bottom: 60px;
  margin-top: 30px;
  @include tablet {
    padding-bottom: 40px;
  }
  @include mobile {
    padding-bottom: 16px;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__step {
    font-family: $inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 28.6px;
    opacity: 0.6;
    @include tablet {
      font-size: 18px;
      line-height: 23.4px;
    }
    @include tablet-portrait {
      font-size: 14px;
      line-height: 18.2px;
    }
    @include mobile {
      font-size: 12px;
      line-height: 15.6px;
      flex-shrink: 0;
      margin: 0 7px;
    }
  }
}

.footer-link {
  width: 282px;
  height: 72px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-family: $inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 28.6px;
  z-index: 1;
  @include tablet {
    width: 184px;
    height: 52px;
    font-size: 18px;
    line-height: 23.4px;
  }
  @include tablet-portrait {
    width: 165px;
    height: 40px;
    font-size: 14px;
    line-height: 18.2px;
  }
  @include mobile {
    width: 125px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  svg:not(.footer-link-bg) {
    @include tablet {
      width: 22px;
    }
    @include tablet-portrait {
      width: 17px;
    }
    @include mobile {
    }
  }

  &__left {
    @include hover {
      opacity: 0.5;
    }
    svg:not(.footer-link-bg) {
      margin-right: 10px;
      @include tablet-portrait {
        margin-right: 5px;
      }
    }
  }

  &__right {
    color: $dark;
    z-index: 1;

    @include hover {
      .footer-link-bg {
        path {
          fill: #e7e7e7;
        }
      }
    }
    svg:not(.footer-link-bg) {
      margin-left: 10px;
      transform: scaleX(-1);
      @include tablet-portrait {
        margin-left: 5px;
      }
      path {
        stroke: $dark;
      }
    }

    .footer-link-bg {
      z-index: -1;
      path {
        fill: $white;
      }
    }
  }
}
