@import '../../assets/styles/vars.scss';

.text {
  width: 840px;
  @include tablet {
    width: 592px;
  }
  @include tablet-portrait {
    margin-top: 60px;
    width: 524px;
  }
  @include mobile {
    margin-top: 30px;
    width: 100%;
  }
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    @include tablet {
      margin-bottom: 16px;
    }
    @include tablet-portrait {
      margin-bottom: 12px;
    }
    @include mobile {
      margin-bottom: 8px;
    }
  }
}

.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  @include tablet-portrait {
    flex-direction: column-reverse;
  }
}

.first {
  font-family: $bonanova;
  font-size: 68.57px;
  line-height: 72px;
  color: $orange;
  position: relative;
  top: 2px;
  @include tablet {
    font-size: 40px;
    line-height: 42px;
    top: 1px;
  }
  @include mobile {
    font-size: 36px;
    line-height: 37.8px;
  }
}

.videoBlock {
  position: relative;
  width: 100%;
  @include tablet-portrait {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  video {
    width: 819px;
    position: absolute;
    right: 0;
    top: 11px;
    @include tablet {
      width: 543px;
      top: -19px;
    }
    @include tablet-portrait {
      position: relative;
      width: 464px;
    }
    @include mobile {
      width: 271px;
      max-width: 100%;
      margin-top: -10px;
    }
  }
}
